import React, { useEffect, useState } from "react";

import Modal from "components/Modal";
import CircleXIcon from "components/constant/icons/CircleXIcon";
import Label from "components/Form/Label";
import Dropdown from "components/Form/Dropdown/Dropdown";
import ButtonForm from "components/Button/ButtonForm";
import { useFormatForDropDownOptions } from "hooks/useDropDownOption";
import { useBookingRequestMutation } from "data/api/booking";
import showToast from "utils/showToast";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch, useAppSelector } from "store";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { SearchField } from "components/Form/SearchField";
import { PuchaseOptions, purchaseOptions } from "enums/purchaseOption";
import { Primise } from "enums/primisesType";
import { primisesTypeData } from "modules/Apartment/data";
import { ResponseStatusType } from "data/api/exception";
import { useRoles } from "hooks/useRoles";
import { selectUser } from "store/UserReducer/selectors";
import { decreaseBookingCount } from "store/UserReducer";
import useGetBuildingByProjectId from "hooks/commonApiCalls/useGetBuildingsbyProjectId";
import useGetProjectByCityId from "hooks/commonApiCalls/useGetProjectByCityId";
import useNotBookedPrimises from "hooks/commonApiCalls/useGetNotBookedPrimises";
import BookingModalCompleted from "./BookingModalCompleted";
import Loader from "components/Loader";

interface PropsType {
  isOpen: boolean;
  handleToggle: () => void;
  primise_id?: number;
  refetch?: () => void;
  client_info?: any;
}

export type BookingForm = {
  client: number;
  project: number;
  building: number;
  type: Primise;
  primise: number;
  purchase_option: PuchaseOptions;
};

const BookingFrom = ({
  isOpen,
  handleToggle,
  primise_id,
  refetch,
  client_info,
}: PropsType) => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);

  const [bookingMutate] = useBookingRequestMutation();

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
    watch,
  } = useForm<BookingForm>();

  const selection = useSelector((state: RootState) => state.header);

  const [loading, setLoading] = useState(false);

  const [isOpenCompleted, setIsOpenCompleted] = useState(false);

  const roles = useRoles(null);

  const { projects } = useGetProjectByCityId({
    cityId: selection.city_id,
  });

  const { building } = useGetBuildingByProjectId({
    projectID: useWatch({
      control,
      name: "project",
    }),
  });

  const { primises } = useNotBookedPrimises({
    control,
  });

  const optionsProject = useFormatForDropDownOptions(projects ?? []);

  const optionsBuilding = useFormatForDropDownOptions(building ?? []);

  const optionsPrimises = useFormatForDropDownOptions(primises ?? []);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "project") {
        reset(
          { building: undefined, type: undefined, primise: undefined },
          { keepValues: true }
        );
      } else if (name === "building") {
        reset({ type: undefined, primise: undefined }, { keepValues: true });
      } else if (name === "type") {
        reset({ primise: undefined }, { keepValues: true });
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, reset]);

  const onSubmit: SubmitHandler<BookingForm> = async (data) => {
    setLoading(true);

    try {
      const { data: res, error } = await bookingMutate({
        premise_id: primise_id ? Number(primise_id) : Number(data.primise),
        client_id: client_info?.id
          ? Number(client_info?.id)
          : Number(data.client),
        purchase_option: data.purchase_option,
      });

      if (res?.meta?.type === ResponseStatusType.SUCCESS) {
        setTimeout(() => {
          handleToggle();
          reset();
          refetch?.();
          dispatch(decreaseBookingCount());
          setLoading(false);
          setIsOpenCompleted(true);
        }, 30000);
      } else if (error?.message) {
        throw new Error(error.message);
      }
    } catch (err) {
      setLoading(false);
      if (err instanceof Error) {
        showToast(err.message, "error");
      } else {
        showToast("An unexpected error occurred", "error");
      }
    }
  };

  const getValue = (
    opts: { label: string; value: string | number }[],
    val: number | string | undefined
  ) => {
    if (val) {
      return opts.filter((o) => o.value == val);
    }
    return null;
  };

  const handleCancel = () => {
    handleToggle();
  };

  const bookingLimit = user?.meta?.data?.remaining_user_creation_limit ?? 0;

  return (
    <>
      <Modal
        className=" w-full md:w-[500px]  rounded-t-[30px] md:rounded-[30px] overflow-y-auto"
        isOpen={isOpen}
      >
        <div
          id={client_info?.id}
          className=" text-accent-default mb-[20px] flex items-center justify-between"
        >
          <h1 className=" text-[25px] font-extrabold leading-[30px]">
            Бронирование
          </h1>
          <button onClick={handleCancel}>
            <CircleXIcon />
          </button>
        </div>

        {loading ? (
          <div className="h-[150px]">
            <Loader />
          </div>
        ) : (
          <>
            {/* {client_info && (
              <div className={"mb-[30px]"}>
                <p className={"text-sm mb-[10px]"}>
                  <span className="mr-2"> Номер сделки:</span>

                  <span className={"text-accent-default font-medium"}>
                    {client_info?.leads[0]?.lead_number}
                  </span>
                </p>

                <p className={"text-sm mb-[10px]"}>
                  <span className="mr-2"> ФИО:</span>

                  <span className={"text-accent-default font-medium"}>
                    {client_info?.fullname}
                  </span>
                </p>

                <p className={"text-sm mb-[10px]"}>
                  <span className="mr-2">Номер:</span>
                  <span className={"text-accent-default font-medium"}>
                    +7 {formatPhoneNumber(client_info?.phone.slice(1))}
                  </span>
                </p>
              </div>
            )} */}

            <form
              onSubmit={handleSubmit(onSubmit)}
              className={
                "flex flex-col gap-[20px] max-h-[60vh] pr-[10px] pl-[2px] overflow-y-auto toolbar-scroll "
              }
            >
              {!client_info?.id && (
                <Label label="Клиент">
                  <Controller
                    name="client"
                    control={control}
                    rules={{
                      required: {
                        value: !client_info?.id,
                        message: "Клиент обязателен для заполнения",
                      },
                    }}
                    render={({ field: { onChange } }) => (
                      <SearchField
                        onChange={(value) => onChange(value?.id)}
                        className="rounded-[4px!important]"
                      />
                    )}
                  />
                </Label>
              )}

              {!primise_id && (
                <>
                  <Label
                    label="Проект"
                    // labelLink={{ label: "Открыть проекты", to: "/projects" }}
                  >
                    <Controller
                      name="project"
                      control={control}
                      rules={{
                        required: {
                          value: !primise_id,
                          message: "Проект обязателен для заполнения",
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Dropdown
                          name="project"
                          value={getValue(optionsProject, value)}
                          options={optionsProject}
                          onChange={(value) => onChange(value.value)}
                          placeholder="Проект не выбран"
                          errors={errors}
													isSearchable
                        />
                      )}
                    />
                  </Label>

                  <Label label="Объект">
                    <Controller
                      name="building"
                      control={control}
                      rules={{
                        required: {
                          value: !primise_id,
                          message: "Объект обязателен для заполнения",
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Dropdown
                          name="building"
                          value={getValue(optionsBuilding, value)}
                          options={watch("project") ? optionsBuilding : []}
                          onChange={(value) => onChange(value.value)}
                          placeholder="Объект не выбран"
                          errors={errors}
                        />
                      )}
                    />
                  </Label>

                  <Label label="Вид помещения">
                    <Controller
                      name="type"
                      control={control}
                      rules={{
                        required: {
                          value: !primise_id,
                          message: "Вид помещения обязателен для заполнения",
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Dropdown
                          name="type"
                          value={getValue(primisesTypeData, value)}
                          options={primisesTypeData}
                          onChange={(value) => onChange(value.value)}
                          placeholder="Вид помещения не выбран"
                          errors={errors}
                        />
                      )}
                    />
                  </Label>

                  <Label label="Номер помещения">
                    <Controller
                      name="primise"
                      control={control}
                      rules={{
                        required: {
                          value: !primise_id,
                          message: "Номер помещения",
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Dropdown
                          name="type"
                          options={watch("type") && optionsPrimises}
                          value={getValue(optionsPrimises, value)}
                          onChange={(value) => onChange(value.value)}
                          placeholder="Номер помещения"
                          errors={errors}
													isSearchable
                        />
                      )}
                    />
                  </Label>
                </>
              )}

              <Label label="Способ покупки">
                <Controller
                  name="purchase_option"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Вариант покупки обязателен для заполнения",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Dropdown
                      name="purchase_options"
                      options={purchaseOptions}
                      value={getValue(purchaseOptions, value)}
                      onChange={(value) => onChange(value.value)}
                      placeholder="Способ покупки не выбран"
                      errors={errors}
                      menuPosition="fixed"
                      // menuPlacement="auto"
                    />
                  )}
                />
              </Label>
              {(roles.isAgent || roles.isHead_of_agency) && (
                <p
                  className={` text-sm text-center ${
                    bookingLimit <= 0
                      ? "text-red-default"
                      : "text-accent-default"
                  }`}
                >
                  Осталось бронирований: {bookingLimit <= 0 ? 0 : bookingLimit}
                </p>
              )}

              <div className="flex gap-[10px]">
                <ButtonForm
                  disabled={!isValid || loading}
                  text="Забронировать"
                  type="submit"
                  className="py-3 px-6
        bg-accent-default flex-1
        text-white-default flex justify-center
        hover:bg-accent-disabled
        hover:
        "
                />
                {/* <ButtonForm
            text="Удалить"
            className=" py-3 px-6 bg-white-default text-accent-default
            border border-accent-default
        hover:bg-accent-default flex-1
        hover:text-white-default flex justify-center
        "
          /> */}
              </div>
            </form>
          </>
        )}
      </Modal>

      <BookingModalCompleted
        isOpen={isOpenCompleted}
        setIsOpen={() => setIsOpenCompleted(false)}
        count={bookingLimit}
      />
    </>
  );
};

export default BookingFrom;
