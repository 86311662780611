import React, { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Label from "components/Form/Label";
import { useChooseAgencyMutation } from "data/api/auth";
import { useSearchAgenciesQuery } from "data/api/agency";
import { yupResolver } from "@hookform/resolvers/yup";
import { ApiException } from "data/api/exception";
import Loader from "components/Loader";
import { RegisterFormState, agentFormDataType } from "modules/Auth/types";
import DatePickerField from "components/Form/DatePicker/DatePickerField";
import CheckBox from "components/Form/CheckBox";
import ButtonForm from "components/Button/ButtonForm";
import { schemaChooseAgent } from "utils/schemas";
import { Step } from "enums/authEnums";
import SearchAgency from "components/SearchAgency";
import { useWindowSize } from "hooks/useWindowSize";

type propsType = {
  title?: React.ReactNode;
  setFormState: React.Dispatch<React.SetStateAction<RegisterFormState>>;
};

const AttachToAgencyForm = ({ setFormState, title }: propsType) => {
  const [checked, setChecked] = useState(false);

  const [searchField, setSearchField] = useState("");

  const [selectedAgent, setSelectedAgent] = useState<number | null>(null);

  const [debouncedTerm, setDebouncedTerm] = useState<string>(searchField);

  const { data: searchResult, isLoading: loading } =
    useSearchAgenciesQuery(debouncedTerm);

  const [mutate, { isLoading }] = useChooseAgencyMutation();

  const { isMd } = useWindowSize();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm<agentFormDataType>({
    resolver: yupResolver<agentFormDataType | any>(schemaChooseAgent),
  });

  const onSubmit: SubmitHandler<agentFormDataType> = async (data) => {
    const user_id = Number(localStorage.getItem("userId") as string);
    if (!checked && selectedAgent) {
      try {
        const { data: res, error } = await mutate({
          data: {
            ...data,
            user_id,
            agency_id: selectedAgent,
          },
        });
        if (res?.data.accessToken) {
          setFormState({ step: Step.SUCCESS_MESSAGE });
          localStorage.setItem("token", res.data.accessToken);
          localStorage.removeItem("register_status");
          localStorage.removeItem("registerType");
        }
        const err = error as ApiException;
        if (err.status === 500) {
          setError("agency_id", {
            type: "manual",
            message: "Пожалуйста, выберите ваше агентство",
          });
          return;
        }
      } catch (err) {
        const error = err as ApiException;
        console.log("err", error);
      }
    } else {
      setFormState({ step: Step.ATTACHMENT_ISNOTEXIST_FORM });
    }
  };

  const handleClick = () => {
    if (checked) {
      setFormState({ step: Step.ATTACHMENT_ISNOTEXIST_FORM });
    }
  };
  const maxDate = new Date();

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-[17px]"
    >
      <h3 className="text-[25px] text-accent-default font-dewi font-extrabold leading-[30px] mb-[10px]">
        {title}
      </h3>
      {isLoading && <Loader />}
      <Label label="Начало работы в АН">
        <Controller
          name="startWorkDate"
          control={control}
          render={({ field: { onChange, value } }) => (
            <DatePickerField
              onChangeDate={onChange}
              className={`w-full ${!isMd && "calendar-block"}`}
              placeholder="Выберите дату"
              isRightIcon={true}
              maxDate={maxDate}
              isIcon
              value={value}
              name="startWorkDate"
              errors={errors}
            />
          )}
        />
      </Label>
      <SearchAgency
        searchResult={searchResult?.data}
        searchField={searchField}
        setSearchField={setSearchField}
        setDebouncedTerm={setDebouncedTerm}
        setSelectedAgent={setSelectedAgent}
        loading={loading}
      />
      <CheckBox
        label="Моего агентства нету в списке"
        onChange={() => setChecked(!checked)}
        value={checked}
        name="isNotOnTheList"
      />
      <div className="flex flex-col gap-[10px] mt-[10px]">
        <ButtonForm
          className="bg-accent-default py-[12px] w-full text-white-default text-center flex justify-center hover:bg-accent-focus"
          text="Далее"
          disabled={!checked && (!selectedAgent || !searchField)}
          type={checked ? "button" : "submit"}
          onClick={handleClick}
        />
        <ButtonForm
          className="bg-bg-default py-[12px] w-full text-accent-default text-center flex justify-center hover:bg-accent-default hover:text-white-default"
          text="Назад"
          onClick={() => setFormState({ step: Step.SELECT_REGISTER_TYPE })}
        />
      </div>
    </form>
  );
};

export default AttachToAgencyForm;
