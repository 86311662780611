import React, { Dispatch, SetStateAction, useState } from "react";
import Modal from "components/Modal";
import ButtonForm from "components/Button/ButtonForm";
import CloseWithBorderIcon from "components/constant/icons/CloseWithBorderIcon";
import Label from "components/Form/Label";
import SearchAgency from "components/SearchAgency";
import ToggleSwitcher from "components/ToggleSwitcher";
import { useGetAllUsersQuery } from "data/api/profile";
import { useSearchAgenciesQuery } from "data/api/agency";
import { useInviteUsersToEventMutation } from "data/api/event";
import { User } from "data/api/profile/type";
import showToast from "utils/showToast";
import { images } from "utils/resource";
import { Event } from "data/api/event/type";
import SelectionToggle from "components/SelectionToggle";
import PlusIcon from "components/constant/icons/PlusIcon";
import CancelIcon from "components/constant/icons/CancelIcon";
import TickIcon from "components/constant/icons/TickIcon";

interface Props {
  isRecordAgentModalOpen: boolean;
  setIsRecordAgentModalOpen: Dispatch<SetStateAction<boolean>>;
  eventData?: Event;
  refetch: () => void;
}

enum InviteType {
  AGENT = "Agent",
  AGENCY = "Agency",
}
const InviteUserToEventModal = ({
  isRecordAgentModalOpen,
  setIsRecordAgentModalOpen,
  eventData,
  refetch,
}: Props) => {
  const [showAgentDropDown, setShowAgentDropDown] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [selectedAgents, setSelectedAgents] = useState<User[]>([]);
  const [inviteTypes, setInviteTypes] = useState<InviteType>(InviteType.AGENT);

  const [debouncedTerm, setDebouncedTerm] = useState<string>(searchField);

  const { data: searchResult } = useSearchAgenciesQuery(debouncedTerm);
  const { data: agents } = useGetAllUsersQuery();
  const [inviteUserMutation] = useInviteUsersToEventMutation();
  const selectedAgentsIds = selectedAgents.map((agent) => agent.id);

  const handleInviteUsersToEvent = async () => {
    const { data: res, error } = await inviteUserMutation({
      data: {
        id: eventData?.id,
        agency_id: searchField.length ? searchResult?.data?.[0]?.id : null,
        user_ids: selectedAgentsIds,
      },
    });
    if (error?.message) {
      showToast(error.message, "error", 2000);
      return;
    }
    if (res?.data.length) {
      showToast("Приглашение успешно получено!", "success", 2000);
      setIsRecordAgentModalOpen(false);
      setSelectedAgents([]);
      setSearchField("");
      refetch();
      return;
    }
  };

  const handleInviteTypeChange = (type: InviteType) => {
    setInviteTypes(type);
  };

  const handleAddUser = (agent: User) => {
    if (isExist(agent.id)) {
      setSelectedAgents((prev) => prev.filter((item) => item.id !== agent.id));
    } else {
      setSelectedAgents([...selectedAgents, agent]);
    }
    setShowAgentDropDown(false);
  };

  const handleDeleteUser = (id: number) => {
    setSelectedAgents((prevAgents) =>
      prevAgents.filter((agent) => agent.id !== id)
    );
  };

  const isExist = (id: number) => {
    if (selectedAgents.length) {
      const indexOf = selectedAgents.findIndex((item) => item.id === id);

      return indexOf !== -1;
    }
  };

  return (
    <Modal
      isOpen={isRecordAgentModalOpen}
      className="mx-auto w-full sm:w-[440px] rounded-t-[30px] rounded-b-0 sm:rounded-lg"
    >
      <div>
        <div className={"flex justify-between mb-[20px]"}>
          <h1 className={"font-extrabold text-[20px] font-dewi"}>
            Запись пользователя
          </h1>
          <ButtonForm
            text={<CloseWithBorderIcon />}
            onClick={() => setIsRecordAgentModalOpen(false)}
          />
        </div>
        <SelectionToggle
          hasButton={false}
          leftLabel="Агент"
          rightLabel="Агентство"
          options={{
            LEFT: InviteType.AGENT,
            RIGHT: InviteType.AGENCY,
          }}
          selectedOption={inviteTypes}
          onSubmit={setInviteTypes}
          handleChange={handleInviteTypeChange}
        />
      </div>

      <div className="flex flex-col gap-5 mt-5 ">
        {inviteTypes === InviteType.AGENT ? (
          <div>
            <Label label="Выберите агента">
              <div className="relative cursor-pointer">
                <div
                  onClick={() => setShowAgentDropDown(!showAgentDropDown)}
                  className="py-1 px-4 flex justify-center items-center w-full bg-light-blue rounded-[4px] h-[48px]"
                >
                  <PlusIcon color="#37465B" />
                </div>
                {showAgentDropDown && (
                  <div className="flex flex-col absolute top-[50px] w-full bg-white-default z-10 border border-boder-default h-[200px] overflow-y-auto toolbar-scroll shadow">
                    {agents?.data.map((agent) => (
                      <div
                        key={agent.id}
                        onClick={() => handleAddUser(agent)}
                        className="py-1 px-4 flex justify-between  border-b border-boder-default items-center hover:bg-bg-default w-full bg-white-default rounded-[4px]"
                      >
                        <img
                          src={
                            agent?.avatar
                              ? process.env.REACT_APP_FILES_URL +
                                "/" +
                                agent?.avatar
                              : images.profileImg
                          }
                          crossOrigin="anonymous"
                          width={26}
                          height={26}
                          alt="img user"
                          className=" w-[26px] h-[26px] rounded-full"
                        />
                        <div className="w-full pl-3">
                          <p className="text-sm font-semibold">
                            {agent.fullName}
                          </p>
                          <p className="text-[12px] font-normal">
                            {agent?.agency?.legalName}
                          </p>
                        </div>
                        {isExist(agent.id) && (
                          <div>
                            <TickIcon color="#36AF22" />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Label>
            <div className="flex flex-col gap-2 mt-3 max-h-[30vh] overflow-y-auto toolbar-scroll pr-3">
              {selectedAgents?.map((agent, idx) => (
                <div
                  key={`agend-idx-${idx}`}
                  className="p-3 flex justify-between items-center w-full bg-white-default border border-boder-default rounded-[4px]"
                >
                  <img
                    src={
                      agent?.avatar
                        ? process.env.REACT_APP_FILES_URL + "/" + agent?.avatar
                        : images.profileImg
                    }
                    crossOrigin="anonymous"
                    width={26}
                    height={26}
                    alt="img user"
                    className=" w-[26px] h-[26px] rounded-full"
                  />
                  <div className="w-full pl-3">
                    <p className="text-sm font-semibold">{agent.fullName}</p>
                    <p className="text-[12px] font-normal">
                      {agent.agency?.legalName}
                    </p>
                  </div>
                  <button
                    className="outline-none"
                    onClick={() => handleDeleteUser(agent.id)}
                  >
                    <CancelIcon color="#EF3B24" />
                  </button>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <SearchAgency
            searchField={searchField}
            setSearchField={setSearchField}
            setDebouncedTerm={setDebouncedTerm}
            searchResult={searchResult?.data}
          />
        )}
        {inviteTypes === InviteType.AGENCY ? (
          <ToggleSwitcher label="Записать всех участников агенства" />
        ) : null}
        <ToggleSwitcher label="Отправить пуш участникам" />
      </div>

      <div className="pt-[30px] flex flex-col gap-[10px]">
        <ButtonForm
          onClick={handleInviteUsersToEvent}
          text="Записать"
          className="py-3 px-6 border bg-accent-default xl:flex-1 flex justify-center text-white-default w-full hover:bg-accent-disabled hover:text-bg-default"
        />
        <ButtonForm
          onClick={() => setIsRecordAgentModalOpen(false)}
          text="Отмена"
          className="py-3 px-6 bg-white-default border border-accent-default hover:bg-accent-default xl:flex-1 flex justify-center hover:text-white-default w-full"
        />
      </div>
    </Modal>
  );
};

export default InviteUserToEventModal;
