import React, { useEffect, useState } from "react";
import ButtonForm from "components/Button/ButtonForm";
import AddUserIcons from "components/constant/icons/AddUserIcons";
import PeopleIcon from "components/constant/PeopleIcon";

import PageTitle from "components/PageTitle";
import AdminisFilter, { AdminSearch } from "./AdminsFilter";
import AdminTableContainer from "components/Form/Table/AdministrationTable";
import AdminsModals from "./AdminsModals";
import { useRoles } from "hooks/useRoles";
import AdminSittings from "./AdminSittings";
import { useGetAllUsersQuery } from "data/api/profile";
import { User } from "data/api/profile/type";
import { PaginationType } from "modules/BaseClients";
import { objectToQueryString } from "utils/objectToQuery";
import Loader from "components/Loader";
import { useWindowSize } from "hooks/useWindowSize";
import AdminMobile from "./AdminMobile";
import { roleOptions, UserRole } from "enums/users";
import AnimationFrame from "components/AnimationFrame.tsx";
import PersonIcon from "components/constant/icons/adminstration/PersonIcon";
import { images } from "utils/resource";

enum Tab {
  USERS = "users",
  SETTINGS = "settings",
}

export interface iFilter {
  page: number;
  limit: number;
  text?: string;
  role?: UserRole | null;
  city_id?: number | null;
  registered_at?: string;
  sort_by?: string;
  order_by?: string;
  is_verified: boolean;
}

const Adminstartion = () => {
  const roles = useRoles(null);

  const [isVerifyModal, setIsVerifyModal] = useState(false);

  const [user, setUser] = useState<User>();

  const [isEditModal, setIsEditModal] = useState(false);

  const [tab, setTab] = useState(Tab.USERS);

  const [paginationInfo, setPaginationInfo] = useState<
    PaginationType | undefined
  >({
    currPage: 0,
    limit: 0,
    total: 0,
    totalPage: 0,
  });
  const [filter, setFilter] = useState<iFilter>({
    page: 1,
    limit: 10,
    is_verified: true,
  });
  const [isFilterChanged, setIsFilterChanged] = useState(false);

  const { data, isLoading, refetch } = useGetAllUsersQuery(
    objectToQueryString({
      ...filter,
      // is_verified: !filter.is_verified ? filter.is_verified : undefined,
    })
  );

  const [contentTable, setContentTable] = useState<User[] | undefined>([]);

  const [headerTable, setHeaderTable] = useState<
    { key: keyof User; title: string; sortable: boolean }[]
  >([
    {
      key: "fullName",
      title: "ФИО",
      sortable: true,
    },
    {
      key: "agency",
      title: "Агенство",
      sortable: true,
    },
    {
      key: "roleText",
      title: "Роль",
      sortable: true,
    },
    {
      key: "city",
      title: "Город",
      sortable: true,
    },
    {
      key: "status",
      title: "Статус",
      sortable: true,
    },
    {
      key: "created_at",
      title: "Регистрация",
      sortable: true,
    },
  ]);

  const windowSize = useWindowSize();

  const changeData = (list: any) => {
    return list.map((item: any) => ({
      ...item,
      roleText: roleOptions.find((i) => i.value === item.role)?.label,
    }));
  };

  useEffect(() => {
    init();
  }, [data?.data]);

  function init() {
    if (isFilterChanged) {
      setContentTable(changeData(data?.data || []));
    } else {
      setContentTable((prevData) => {
        return prevData
          ? [...prevData, ...changeData(data?.data || [])]
          : changeData(data?.data);
      });
    }
    setPaginationInfo(data?.meta?.links);
  }

  const handleClickTableItem = (user: User) => {
    setUser(user);
    if (!user.is_verified) {
      setIsVerifyModal(true);
    }
  };

  const handleEditTableItem = (user: User) => {
    setUser(user);
    if (!user.is_verified) {
      setIsVerifyModal(true);
    } else {
      setIsEditModal(true);
    }
  };

  function handleUpdateList() {
    const filterUpdate = {
      ...filter,
      page: filter.page + 1,
    };
    setFilter(filterUpdate);
    setIsFilterChanged(false);
  }

  function handleChangeFilter(
    text?: string,
    role?: UserRole | null,
    city_id?: number | null,
    registered_at?: string,
    order_by?: string,
    sort_by?: string
  ) {
    setFilter({
      ...filter,
      page: 1,
      text,
      role,
      city_id,
      registered_at,
      order_by,
      sort_by,
    });
    setIsFilterChanged(true);
  }

  function handleSortBy(sort_by: string, order_by: string) {
    setFilter({
      ...filter,
      page: 1,
      sort_by,
      order_by,
    });
    setIsFilterChanged(true);
  }

  function handleSortOnlyNewUser() {
    setIsFilterChanged(true);
    const filterUpdate: iFilter = {
      ...filter,
      is_verified: !filter.is_verified,
    };
    setFilter(filterUpdate);
  }

  return (
    <section className=" bg-bg-default">
      <div className="br-container py-4 min-h-[50vh]">
        {roles.isAdmin ? (
          <div className=" flex gap-[30px] border-b mb-4 border-boder-default ">
            <button
              onClick={() => setTab(Tab.USERS)}
              className={`text-accent-default text-sm font-normal outline-none py-3 ${
                tab === Tab.USERS && "border-b-2 border-accent-default"
              }`}
            >
              Пользователи
            </button>
            <button
              onClick={() => setTab(Tab.SETTINGS)}
              className={`text-accent-default text-sm font-normal outline-none py-3 ${
                tab === Tab.SETTINGS && "border-b-2 border-accent-default"
              }`}
            >
              Настройки
            </button>
          </div>
        ) : null}

        {tab === Tab.USERS ? (
          <>
            <div className="flex items-center justify-between">
              <div className="flex flex-col md:flex-row gap-1 md:gap-5 md:items-center">
                <PageTitle className=" text-[18px]" text="ООО Ломонова" />
                <div className="flex items-center gap-2">
                  <PeopleIcon />
                  <p className="text-sm text-accent-default font-medium">
                    {data?.meta?.data.total_user_count} пользователей
                  </p>
                </div>
              </div>
              {!roles.isAffiliate_manager ? (
                <div className="relative">
                  <ButtonForm
                    onClick={handleSortOnlyNewUser}
                    text={
                      windowSize.isLg && !isLoading
                        ? filter.is_verified
                          ? `${data?.meta?.data.new_user_count} новых пользователей`
                          : "Все пользователи"
                        : ""
                    }
                    leftIcon={
                      !isLoading && filter.is_verified ? (
                        <AddUserIcons
                          color={windowSize.isLg ? "#ffffff" : "#37465B"}
                        />
                      ) : (
                        <PersonIcon />
                      )
                    }
                    className={`py-3 px-3 sm:px-6 ${
                      windowSize.isLg
                        ? `${
                            filter.is_verified
                              ? "bg-accent-default text-white-default hover:bg-accent-disabled"
                              : " bg-white-default text-accent-default"
                          }  `
                        : "bg-white-default"
                    }  lg:w-[245px]  flex justify-center `}
                  />
                  {!windowSize.isLg && (
                    <div className=" absolute w-[25px] h-[25px] rounded-full text-white-default bg-red-default flex items-center justify-center text-[12px] -right-[8px] -bottom-[8px]">
                      {data?.meta?.data?.new_user_count &&
                      data?.meta?.data?.new_user_count > 99
                        ? "+99"
                        : `+${data?.meta?.data?.new_user_count}`}
                    </div>
                  )}
                </div>
              ) : null}
            </div>

            <AdminisFilter
              filter={filter}
              handleChangeFields={handleChangeFilter}
            />

            <AdminSearch
              filter={filter}
              handleChangeFields={handleChangeFilter}
            />

            {isLoading ? (
              <div className=" relative h-[200px]">
                <Loader />
              </div>
            ) : (
              <div className="py-5">
                {windowSize.isLg ? (
                  <AnimationFrame>
                    <AdminTableContainer
                      handleSortBy={handleSortBy}
                      header={headerTable}
                      content={contentTable}
                      setData={setContentTable}
                      onLeftClickTrContent={handleClickTableItem}
                      handleEditTableItem={handleEditTableItem}
                      loading={isLoading}
                    />
                  </AnimationFrame>
                ) : (
                  <AdminMobile
                    user={data?.data}
                    handleEditItem={handleEditTableItem}
                    handleClickItem={handleClickTableItem}
                    handleChangeFields={handleChangeFilter}
                  />
                )}

                {paginationInfo
                  ? !(
                      paginationInfo?.currPage >= paginationInfo?.totalPage
                    ) && (
                      <button
                        type={"button"}
                        className={
                          "py-3 px-6 mx-auto text-blue-default text-sm font-medium flex mt-[15px]"
                        }
                        onClick={() => handleUpdateList()}
                      >
                        Загрузить еще
                      </button>
                    )
                  : null}
              </div>
            )}

            <AdminsModals
              refetch={refetch}
              user={user}
              isVerifyModal={isVerifyModal}
              setIsVerifyModal={setIsVerifyModal}
              isEditModal={isEditModal}
              setIsEditModal={setIsEditModal}
            />
          </>
        ) : (
          <AnimationFrame>
            <AdminSittings />
          </AnimationFrame>
        )}
      </div>
    </section>
  );
};

export default Adminstartion;
