import React, { FC, ReactNode, useState } from "react";
import ArrowTableIcon from "../../constant/icons/Table/ArrowTableIcon";
import RadioButton from "components/RadioSelectionButton";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { formatPhoneNumber } from "hooks/useFormatInput";
import {
  ExpireDateColumn,
  getItemStatusParking,
  renderColorByType,
} from "components/Form/Table/utils";
import { useLocation, useNavigate } from "react-router";
import Loader from "components/Loader";

export interface DataItem {
  [key: string]: any;
}

export interface TableColumn {
  key: keyof DataItem;
  title: string;
  width?: string;
  sortable?: boolean;
}

interface TableContainerProps {
  header: TableColumn[];
  content: DataItem[];
  setData?: (list: DataItem[]) => void;
  sortable?: boolean;
  textContentEmpty?: string | ReactNode;
  onRightClickTrContent?: (e: MouseEvent, item: DataItem) => void;
  onLeftClickTrContent?: (e: DataItem) => void;
  isLastRightText?: boolean;
  loading?: boolean;
  tableBodyUnderBlock?: any;
  doSelectData?: boolean;
  handleSelectTableData?: (id: number) => void;
  handleClickTableItem?: () => void;
  handleSortBy?: (sort_by: string, order_by: string) => void;
  orderBy?: string;
  handleJumpToApartmentDetails?: (id: number) => void;
}

const TableContainer: FC<TableContainerProps> = ({
  handleSortBy,
  header,
  content,
  setData,
  sortable = true,
  textContentEmpty = "Пусто",
  onRightClickTrContent,
  onLeftClickTrContent,
  isLastRightText = true,
  loading = false,
  tableBodyUnderBlock,
  doSelectData,
  handleSelectTableData,
  handleClickTableItem,
  handleJumpToApartmentDetails,
  orderBy,
}) => {
  const [sortConfig, setSortConfig] = useState<{
    key: keyof DataItem;
    direction: string;
  } | null>(null);
  const selection = useSelector((state: RootState) => state.apartment);

  const location = useLocation();

  const isClientPage = location.pathname === "/base-clients";

  const isTaskPage =
    location.pathname === "/tasks" || location.pathname === "/";

  const handleSort = (column: TableColumn) => {
    if (!column.sortable && !sortable) return;

    let direction = "DESC";
    if (
      sortConfig &&
      sortConfig.key === column.key &&
      sortConfig.direction === "DESC"
    ) {
      direction = "ASC";
    }
    setSortConfig({ key: column.key, direction });

    const data: any = {
      name: "fullname",
      fixing_type: "fixing_type",
      date: "actived_date",
      datePin: "expiration_date",
    };

    const sortInPages = isTaskPage ? column.key : data[column.key];

    handleSortBy?.(sortInPages, direction);
  };

  const itemExists = (id: number) => {
    return selection.storedData.includes(id);
  };

  function getColumnWidths(headers: string) {
    if (isClientPage) {
      switch (headers) {
        case "name": {
          return "w-[200px] xl:w-[220px] whitespace-nowrap text-ellipsis overflow-hidden";
        }
        case "comment": {
          return "w-[180px] xl:w-[220px] whitespace-nowrap text-ellipsis overflow-hidden";
        }
        case "fixing_type": {
          return "w-[180px] xl:w-[160px]";
        }
        case "phone": {
          return "w-[180px] xl:w-[160px] ";
        }
        default: {
          return "w-[100px] xl:w-[130px]";
        }
      }
    } else if (isTaskPage) {
      switch (headers) {
        case "task_type": {
          return "w-[200px] xl:w-[220px]";
        }
        case "client_fullname": {
          return "w-[200px]";
        }
        case "end_date": {
          return "w-[120px]";
        }
        case "project_name": {
          return "w-[200px]";
        }
        default: {
          return "w-[100px]";
        }
      }
    } else {
      switch (headers) {
        case "name": {
          return "w-[170px]";
        }
        case "building": {
          return "w-[200px] xl:w-[220px]";
        }
        case "price": {
          return "w-[133px]";
        }
        case "status": {
          return "w-[200px] xl:w-[220px]";
        }
        case "floor": {
          return "w-[40px]";
        }

        default: {
          return "w-[70px]";
        }
      }
    }
  }

  return (
    <div className="overflow-x-auto">
      <table className="table-auto border-collapse min-w-full bg-white-default rounded-lg">
        <thead className="table__header">
          <tr className={""}>
            {doSelectData && <th className=""></th>}
            {header.map((column, idx) => {
              const isDESC =
                sortConfig?.key === column.key &&
                sortConfig.direction === "DESC";

              return (
                <th
                  key={column.key}
                  style={{ width: column.width }}
                  className={
                    idx === header.length - 1 && isLastRightText
                      ? "py-4 px-[15px] 2xl:px-5"
                      : "py-4 px-[15px] 2xl:px-5"
                  }
                >
                  <div
                    className={`table__header__th flex ${getColumnWidths(
                      column.key.toString()
                    )}`}
                  >
                    <p className={"mr-2 text-[14px] 2xl:text-base font-normal"}>
                      {column.title}
                    </p>

                    {(column.sortable || sortable) && (
                      <button
                        onClick={() => handleSort(column)}
                        className={`transition-all ${
                          isDESC ? "rotate-180" : ""
                        }`}
                      >
                        {sortConfig?.direction &&
                        sortConfig.direction === "ASC" ? (
                          <ArrowTableIcon />
                        ) : (
                          <ArrowTableIcon />
                        )}
                      </button>
                    )}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody
          className={`table__content ${
            loading ? "table__content-loading" : ""
          }`}
        >
          {content.length ? (
            content.map((item, index) => (
              <React.Fragment key={`table-content-item-${index}`}>
                <tr
                  key={index}
                  onContextMenu={(e: any) => {
                    onRightClickTrContent && onRightClickTrContent(e, item);
                  }}
                  onClick={() => {
                    onLeftClickTrContent && onLeftClickTrContent(item);
                  }}
                  className={
                    "cursor-pointer w-full p-1 border-t-[1px] border-boder-default hover:bg-bg-default"
                  }
                >
                  {doSelectData && (
                    <td className="py-6 pl-[20px]">
                      <div className="pt-[6px]">
                        <RadioButton
                          onChange={() => handleSelectTableData?.(item.id)}
                          checked={itemExists(item.id)}
                        />
                      </div>
                    </td>
                  )}
                  {header.map((column, idx) => {
                    return (
                      <td
                        onClick={handleClickTableItem}
                        key={column.key}
                        colSpan={idx === header.length - 1 ? header.length : 1}
                        className={`py-6 px-3 text-[14px] 2xl:text-[16px] 2xl:px-5 font-normal max-w-[320px] w-[320px] overflow-hidden whitespace-nowrap text-ellipsis  ${
                          idx === header.length - 1 && isLastRightText
                            ? "last"
                            : ""
                        }`}
                        style={{
                          color:
                            column.title === "Закрепление"
                              ? renderColorByType(item[column.key]?.key)
                              : "",
                        }}
                      >
                        <div
                          className={` ${getColumnWidths(
                            column.key.toString()
                          )} `}
                        >
                          {typeof item[column.key] === "object" ? (
                            column.key === "datePin" ? (
                              <ExpireDateColumn
                                expiration_date={item[column.key]?.key}
                              />
                            ) : (
                              item[column.key]?.text
                            )
                          ) : column.title === "Номер телефона" ? (
                            "+7 " + formatPhoneNumber(item[column.key].slice(1))
                          ) : column.key === "name" && !isClientPage ? (
                            <span
                              onClick={() =>
                                handleJumpToApartmentDetails?.(item.id)
                              }
                              className=" text-blue-default underline"
                            >
                              {item[column.key]}
                            </span>
                          ) : column.key === "size" ? (
                            <>
                              {item[column.key]}m<sup>2</sup>
                            </>
                          ) : column.key === "status" ? (
                            getItemStatusParking(item)
                          ) : (
                            item[column.key]
                          )}
                        </div>
                      </td>
                    );
                  })}
                </tr>
                {tableBodyUnderBlock && tableBodyUnderBlock(item)}
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan={header.length}>
                <div className={"flex justify-center items-center"}>
                  {loading ? (
                    <div className="h-[150px] relative">
                      <Loader />
                    </div>
                  ) : (
                    <p className={"m-6"}>{textContentEmpty}</p>
                  )}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableContainer;
